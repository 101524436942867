import React from 'react';
//import logo from './logo.svg';
import './App.css';
//import * as ReactBootstrap from 'react-bootstrap';
//import Amplify, { Auth } from 'aws-amplify';
//import awsconfig from './aws-exports';
//import { AmplifyAuthenticator, withAuthenticator, AmplifySignOut, AmplifySignIn } from '@aws-amplify/ui-react';
//import Authentication from './Authentication.js';
import Content from './Content.js';
import Header from './Header.js';
import Footer from './Footer.js';
//import { Button, Container, Row, Col } from 'bootstrap-4-react';

import { BrowserRouter as Router, withRouter} from 'react-router-dom';
//import {onLCP, onFID, onCLS} from 'web-vitals';

import ReactGA from 'react-ga4';


const TRACKING_ID = "G-RLCMQ3DZZT"; 
//const TRACKING_ID = "G-LGSEBJ6G34";
//const TRACKING_ID = "G-B85R7P6PV8"; 
//const TRACKING_ID = "362061385"; 
ReactGA.initialize(TRACKING_ID, {testMode: false});


//onCLS(console.log("onCLS"));
//onFID(console.log("onFID"));
//onLCP(console.log("onLCP"));

const App = () => {

    return (
        <> 
            <Router>
                <Header />
                    <Content />
                <Footer />
            </Router>
        </>
    );
}


export default App;

